.footer {
    background-color: var(--main-hl-bg);
    color: var(--main-font);
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-column-gap: 30px;
    grid-row-gap: 10px;
    justify-content: center;
    padding: 20px;
}

.title {
    grid-row: 1;
}

.content {
    grid-row: 2;
    font-size: small;
}

@media screen and (max-width: 1000px) {
    .footer {
        grid-template-columns: max-content;
    }

    .title, .content {
        grid-row: unset;
    }

    .content {
        margin-bottom: 10px;
    }
}
