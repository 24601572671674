.headerbar {
    height: var(--header-height);
    padding-left: 15px;
    padding-right: 15px;
    background-color: var(--main-hl-bg);
    color: var(--main-font);

    display: grid;
    grid-template-columns: 1fr repeat(3, max-content);
    grid-template-rows: 1fr;
    grid-column-gap: .5em;
    align-items: center;

    position: sticky;
    top: 0;
}

.logo {
    letter-spacing: 5px;
    font-size: 1.5em;
}

.iconcont {
    all: unset;
    height: calc(0.7 * var(--header-height));
    width: calc(0.7 * var(--header-height));
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    cursor: pointer;
}

.iconcont:hover {
    background-color: var(--main-hl-light-bg);
}

.icon {
    height: calc(0.5 * var(--header-height));
    width: calc(0.5 * var(--header-height));
    fill: #ffffff;
}
