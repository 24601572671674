.container {
    box-shadow: none;
}

.container > * {
    margin: 0;
}

.logocontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logocontainer > img {
    max-width: 400px;
    min-width: 300px;
    width: 40vw;
    height: auto;
}