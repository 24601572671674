.container {
    --title-padding: 20px;
    --desc-padding: calc(var(--title-padding) + 10px);
}

.title {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr max-content;

    padding: 10px 20px;
    border-radius: var(--main-br);

    background-color: var(--main-hl-xlight-bg);
    cursor: pointer;
}

.title p {
    font-weight: 500;
}

.title svg {
    transition: .3s;
    height: 24px;
    width: auto;
}

.description {
    margin: 10px var(--desc-padding) 0 var(--desc-padding);
    overflow-y: hidden;
    height: 0;
    transition: .3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.icon-enter {
    transform: rotateZ(0);
}

.icon-enter-done {
    transform: rotateZ(-90deg);
}

.icon-exit {
    transform: rotateZ(-90deg);
}

.icon-exit-done {
    transform: rotateZ(0);
}

.desc-enter {
    height: 0;
}

.desc-exit-done {
    height: 0;
}