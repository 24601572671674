.teammember {
    display: grid;
    grid-template-columns: max-content;
    grid-template-rows: min-content max-content min-content;
    align-items: center;
    justify-items: flex-start;
    grid-column-gap: 5px;
}

.portrait {
    grid-column: 1;
    grid-row: 1/4;
    width: 144px;
    height: 200px;
    border-radius: var(--main-br);
}

.name {
    font-size: larger;
    font-weight: bolder;
    align-self: flex-end;
}

.role {
    font-size: small;
    background: var(--main-hl-bg);
    color: var(--main-font);
    border-radius: var(--main-br);
    padding: 0 5px;
}

.job {
    font-style: italic;
    font-size: small;
    font-weight: 300;
    align-self: flex-start;
}

@media screen and (max-width: 1000px) {
    .teammember {
        grid-template-columns: max-content max-content;
    }
}