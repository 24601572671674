@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  /*overflow-x: hidden;*/
}

:root {
  /* hl -> Highlight*/
  --main-hl-bg: rgb(66, 66, 66);
  --main-hl-light-bg: rgb(126, 126, 126);
  --main-hl-xlight-bg: rgb(212, 212, 212);
  --main-br: 5px;
  --main-shadow: 2px 2px 31px 5px #6D6D6D;
  --main-font: white;

  --header-height: 6vh;

  --titlepage-font-col: white;
  --titlepage-height: calc(100vh - var(--header-height));
}

.main {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
}

a {
  all: unset;
  color: rgb(0, 215, 249);
  cursor: pointer;
  box-sizing: border-box;
}

a.dark {
  color: rgb(0, 170, 197);
}