.contentcard {
    width: 70%;
    min-width: 950px;
    padding: 20px;
    -webkit-box-shadow: var(--main-shadow);
    box-shadow: var(--main-shadow);
    border-radius: var(--main-br);
    transition: .3s;
}

.title {
    font-size: xx-large;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

@media screen and (max-width: 1000px) {
    .contentcard {
        width: 100%;
        min-width: 100%;
        border-radius: 0;
    }
}
