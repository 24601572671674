@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  /*overflow-x: hidden;*/
}

:root {
  /* hl -> Highlight*/
  --main-hl-bg: rgb(66, 66, 66);
  --main-hl-light-bg: rgb(126, 126, 126);
  --main-hl-xlight-bg: rgb(212, 212, 212);
  --main-br: 5px;
  --main-shadow: 2px 2px 31px 5px #6D6D6D;
  --main-font: white;

  --header-height: 6vh;

  --titlepage-font-col: white;
  --titlepage-height: calc(100vh - var(--header-height));
}

.main {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  grid-gap: 40px;
  gap: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
}

a {
  all: unset;
  color: rgb(0, 215, 249);
  cursor: pointer;
  box-sizing: border-box;
}

a.dark {
  color: rgb(0, 170, 197);
}
.TitlePage_titlepage__2UE2Z {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/background.fcf16a89.webp);
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    width: 100%;
    height: var(--titlepage-height);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--titlepage-font-col);
    grid-gap: 10px;
    gap: 10px;
}

.TitlePage_logo__1zixV {
    max-width: 425px;
    width: 80vw;
    height: auto;
    fill: #ffffff;
}

.TitlePage_description__30weE {
    font-size: x-large;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    text-align: center;
}

.TitlePage_down__2K0in {
    position: absolute;
    width: 50px;
    height: auto;
    bottom: 0;
    --duration: 1.5s;
    --delay: .3s;
    -webkit-animation: TitlePage_downanim__22o_D var(--duration) ease-in var(--delay) infinite;
    animation: TitlePage_downanim__22o_D var(--duration) ease-in var(--delay) infinite;
    opacity: 0;
    cursor: pointer;
    fill: #ffffff;
}

@-webkit-keyframes TitlePage_downanim__22o_D {
    0%,
    100% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
        opacity: 1;
    }
    80% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 0;
    }
    81% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
        opacity: 0;
    }
}

@keyframes TitlePage_downanim__22o_D {
    0%,
    100% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
        opacity: 1;
    }
    80% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 0;
    }
    81% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
        opacity: 0;
    }
}
.HeaderBar_headerbar__38Jz7 {
    height: var(--header-height);
    padding-left: 15px;
    padding-right: 15px;
    background-color: var(--main-hl-bg);
    color: var(--main-font);

    display: grid;
    grid-template-columns: 1fr repeat(3, -webkit-max-content);
    grid-template-columns: 1fr repeat(3, max-content);
    grid-template-rows: 1fr;
    grid-column-gap: .5em;
    align-items: center;

    position: -webkit-sticky;

    position: sticky;
    top: 0;
}

.HeaderBar_logo__3mqGK {
    letter-spacing: 5px;
    font-size: 1.5em;
}

.HeaderBar_iconcont__ugxLx {
    all: unset;
    height: calc(0.7 * var(--header-height));
    width: calc(0.7 * var(--header-height));
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    cursor: pointer;
}

.HeaderBar_iconcont__ugxLx:hover {
    background-color: var(--main-hl-light-bg);
}

.HeaderBar_icon__2gONF {
    height: calc(0.5 * var(--header-height));
    width: calc(0.5 * var(--header-height));
    fill: #ffffff;
}

.ContentCard_contentcard__n3TSy {
    width: 70%;
    min-width: 950px;
    padding: 20px;
    box-shadow: var(--main-shadow);
    border-radius: var(--main-br);
    transition: .3s;
}

.ContentCard_title__2cHcv {
    font-size: xx-large;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

@media screen and (max-width: 1000px) {
    .ContentCard_contentcard__n3TSy {
        width: 100%;
        min-width: 100%;
        border-radius: 0;
    }
}

.DieIdee_content__3bM44 {
    text-align: justify;
}
.TeamMember_teammember__qBkXY {
    display: grid;
    grid-template-columns: -webkit-max-content;
    grid-template-columns: max-content;
    grid-template-rows: -webkit-min-content -webkit-max-content -webkit-min-content;
    grid-template-rows: min-content max-content min-content;
    align-items: center;
    justify-items: flex-start;
    grid-column-gap: 5px;
}

.TeamMember_portrait__1u0n_ {
    grid-column: 1;
    grid-row: 1/4;
    width: 144px;
    height: 200px;
    border-radius: var(--main-br);
}

.TeamMember_name__VrJl7 {
    font-size: larger;
    font-weight: bolder;
    align-self: flex-end;
}

.TeamMember_role__1C32S {
    font-size: small;
    background: var(--main-hl-bg);
    color: var(--main-font);
    border-radius: var(--main-br);
    padding: 0 5px;
}

.TeamMember_job__8EQdE {
    font-style: italic;
    font-size: small;
    font-weight: 300;
    align-self: flex-start;
}

@media screen and (max-width: 1000px) {
    .TeamMember_teammember__qBkXY {
        grid-template-columns: -webkit-max-content -webkit-max-content;
        grid-template-columns: max-content max-content;
    }
}
.DasTeam_teamcontainer__dmeh9 {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media screen and (max-width: 1000px) {
    .DasTeam_teamcontainer__dmeh9 {
        display: grid;
        grid-template-columns: repeat(2, -webkit-max-content);
        grid-template-columns: repeat(2, max-content);
        align-content: center;
        grid-row-gap: 20px;
    }
}

@media screen and (max-width: 800px) {
    .DasTeam_teamcontainer__dmeh9 {
        grid-template-columns: -webkit-max-content;
        grid-template-columns: max-content;
    }
}
.Ziel_container__zhIh4 {
    --title-padding: 20px;
    --desc-padding: calc(var(--title-padding) + 10px);
}

.Ziel_title__3VdWB {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;

    padding: 10px 20px;
    border-radius: var(--main-br);

    background-color: var(--main-hl-xlight-bg);
    cursor: pointer;
}

.Ziel_title__3VdWB p {
    font-weight: 500;
}

.Ziel_title__3VdWB svg {
    transition: .3s;
    height: 24px;
    width: auto;
}

.Ziel_description__2GvKW {
    margin: 10px var(--desc-padding) 0 var(--desc-padding);
    overflow-y: hidden;
    height: 0;
    transition: .3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.Ziel_icon-enter__3Tpsp {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
}

.Ziel_icon-enter-done__3WXMj {
    -webkit-transform: rotateZ(-90deg);
            transform: rotateZ(-90deg);
}

.Ziel_icon-exit__1Z8tx {
    -webkit-transform: rotateZ(-90deg);
            transform: rotateZ(-90deg);
}

.Ziel_icon-exit-done__3re0S {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
}

.Ziel_desc-enter__1mO4C {
    height: 0;
}

.Ziel_desc-exit-done__30VXV {
    height: 0;
}
.DieZiele_zielecontainer__3kMFn {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}
.FooterBar_footer__3bPuO {
    background-color: var(--main-hl-bg);
    color: var(--main-font);
    display: grid;
    grid-template-columns: repeat(3, -webkit-max-content);
    grid-template-columns: repeat(3, max-content);
    grid-column-gap: 30px;
    grid-row-gap: 10px;
    justify-content: center;
    padding: 20px;
}

.FooterBar_title__35VHk {
    grid-row: 1;
}

.FooterBar_content__14fur {
    grid-row: 2;
    font-size: small;
}

@media screen and (max-width: 1000px) {
    .FooterBar_footer__3bPuO {
        grid-template-columns: -webkit-max-content;
        grid-template-columns: max-content;
    }

    .FooterBar_title__35VHk, .FooterBar_content__14fur {
        grid-row: unset;
    }

    .FooterBar_content__14fur {
        margin-bottom: 10px;
    }
}

.Kontakt_kontaktcontainer__3cD6- {
    display: grid;
    grid-template-columns: repeat(3, 0.333fr);
    grid-template-rows: -webkit-max-content;
    grid-template-rows: max-content;
    justify-items: center;
    justify-content: center;
    grid-gap: 1vw;
}

.Kontakt_kontaktmgl__2_BEd {
    display: grid;
    grid-template-columns: -webkit-max-content -webkit-max-content;
    grid-template-columns: max-content max-content;
    grid-template-rows: -webkit-max-content -webkit-max-content;
    grid-template-rows: max-content max-content;
    grid-column-gap: 10px;
    cursor: pointer;
    border-radius: var(--main-br);
    padding: 10px;
    transition: .2s;
    color: #000000;
}

.Kontakt_kontaktmgl__2_BEd:hover {
    background: var(--main-hl-xlight-bg);
}

.Kontakt_icon__1YM4x {
    width: 50px;
    height: auto;
    justify-self: center;
    align-self: center;
    grid-row: 1/3;
    fill: #424242;
}

.Kontakt_short__9nW6q {
    align-self: flex-end;
}

.Kontakt_long__2XgL- {
    font-size: 0.75em;
    color: var(--main-hl-bg);
    align-self: flex-start;
}

@media screen and (max-width: 1000px) {
    .Kontakt_kontaktcontainer__3cD6- {
        grid-template-columns: -webkit-max-content;
        grid-template-columns: max-content;
        grid-template-rows: repeat(3, -webkit-max-content);
        grid-template-rows: repeat(3, max-content);
        justify-items: flex-start;
    }

    .Kontakt_kontaktmgl__2_BEd {
        width: 100%;
    }
}

.ImAuftrag_container__1mrXK {
    box-shadow: none;
}

.ImAuftrag_container__1mrXK > * {
    margin: 0;
}

.ImAuftrag_logocontainer__ZavlN {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ImAuftrag_logocontainer__ZavlN > img {
    max-width: 400px;
    min-width: 300px;
    width: 40vw;
    height: auto;
}
