.teamcontainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media screen and (max-width: 1000px) {
    .teamcontainer {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        align-content: center;
        grid-row-gap: 20px;
    }
}

@media screen and (max-width: 800px) {
    .teamcontainer {
        grid-template-columns: max-content;
    }
}