.titlepage {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../resources/background.webp");
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    width: 100%;
    height: var(--titlepage-height);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--titlepage-font-col);
    gap: 10px;
}

.logo {
    max-width: 425px;
    width: 80vw;
    height: auto;
    fill: #ffffff;
}

.description {
    font-size: x-large;
    font-variant: small-caps;
    text-align: center;
}

.down {
    position: absolute;
    width: 50px;
    height: auto;
    bottom: 0;
    --duration: 1.5s;
    --delay: .3s;
    -webkit-animation: downanim var(--duration) ease-in var(--delay) infinite;
    -moz-animation: downanim var(--duration) ease-in var(--delay) infinite;
    -o-animation: downanim var(--duration) ease-in var(--delay) infinite;
    animation: downanim var(--duration) ease-in var(--delay) infinite;
    opacity: 0;
    cursor: pointer;
    fill: #ffffff;
}

@keyframes downanim {
    0%,
    100% {
        transform: translateY(-20px);
        opacity: 1;
    }
    80% {
        transform: translateY(0);
        opacity: 0;
    }
    81% {
        transform: translateY(-20px);
        opacity: 0;
    }
}