.kontaktcontainer {
    display: grid;
    grid-template-columns: repeat(3, 0.333fr);
    grid-template-rows: max-content;
    justify-items: center;
    justify-content: center;
    grid-gap: 1vw;
}

.kontaktmgl {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-template-rows: max-content max-content;
    grid-column-gap: 10px;
    cursor: pointer;
    border-radius: var(--main-br);
    padding: 10px;
    transition: .2s;
    color: #000000;
}

.kontaktmgl:hover {
    background: var(--main-hl-xlight-bg);
}

.icon {
    width: 50px;
    height: auto;
    justify-self: center;
    align-self: center;
    grid-row: 1/3;
    fill: #424242;
}

.short {
    align-self: flex-end;
}

.long {
    font-size: 0.75em;
    color: var(--main-hl-bg);
    align-self: flex-start;
}

@media screen and (max-width: 1000px) {
    .kontaktcontainer {
        grid-template-columns: max-content;
        grid-template-rows: repeat(3, max-content);
        justify-items: flex-start;
    }

    .kontaktmgl {
        width: 100%;
    }
}
